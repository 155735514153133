// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lauksime-js": () => import("./../src/pages/lauksime.js" /* webpackChunkName: "component---src-pages-lauksime-js" */),
  "component---src-pages-nuostatai-js": () => import("./../src/pages/nuostatai.js" /* webpackChunkName: "component---src-pages-nuostatai-js" */),
  "component---src-pages-privatumas-js": () => import("./../src/pages/privatumas.js" /* webpackChunkName: "component---src-pages-privatumas-js" */),
  "component---src-pages-registracija-js": () => import("./../src/pages/registracija.js" /* webpackChunkName: "component---src-pages-registracija-js" */)
}

